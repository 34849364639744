import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useQueryProcessing = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const params: Record<string, string> = {};

        urlParams.forEach((el, index) => {
            params[index] = el;
        })

        if(params['startUrl']) {
            navigate(params['startUrl'])
        }
    // eslint-disable-next-line
    }, [])
}

export default useQueryProcessing;