import { observer } from "mobx-react"
import KinescopePlayer from '@kinescope/react-kinescope-player'
import { useState } from "react"

import style from './index.module.css'

interface LessonHeaderProps {
    videoId: string,
    onPlay: () => void,
}

const LessonHeader = ({videoId, onPlay}: LessonHeaderProps) => {
    const [currentInterval, setCurrentInterval] = useState<number | null>() 

    return (
        <div className={style.wrapper}>
            <KinescopePlayer
                videoId={videoId}
                width="100%"
                height="200px"
                playsInline={true}
                controls={true}
                onPlay={() => {
                    onPlay();
                }}
                onPause={() => {
                    if(currentInterval) {
                        clearInterval(currentInterval)
                    }
                    setCurrentInterval(null)
                }}
                onError={(error) => {
                    console.error('Kinescope player error:', error);
                }}
            />
        </div>
    )
}

export default observer(LessonHeader)